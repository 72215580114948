<template>
	<div class="" v-loading="loading_load">
		<el-descriptions :title="$t('i18nn_3644a90f9f13f0b8')">
			<el-descriptions-item :label="$t('i18nn_6235565b185f0725')">{{form.workNo}}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_c944a6686d996ab3')">{{form.whNo}}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_7431e76029678ec7')">{{form.title}}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_f566f50ef680920c')">{{ form.orderTypeName }}</el-descriptions-item>
			<el-descriptions-item :label="$t('i18nn_14b5f4f572736efe')">{{form.createTime}}</el-descriptions-item>
			<!-- <el-descriptions-item :label="$t('i18nn_15b3627faddccb1d')">
				<div class="pre-text">
					{{ form.remark }}
				</div>
			</el-descriptions-item> -->
		</el-descriptions>
		
		
		
		<el-descriptions title="">
			<el-descriptions-item :label="$t('i18nn_15b3627faddccb1d')">
				<div class="pre-text">
					{{ form.remark }}
				</div>
			</el-descriptions-item>
		</el-descriptions>
		
		<el-descriptions title="">
			<el-descriptions-item :label="$t('i18nn_f5d43732e3f6cf4d')">
				<ul>
					<li v-for="(item,index) in form.attachments" :key="index">
						<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
							:fit="'contain'" :src="item.url" :preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
							<div slot="error" class="image-slot">
								<i class="el-icon-document"></i>
							</div>
						</el-image>
						<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
		
						<!-- <el-button @click="delAction($event, item)" type="text" size="mini" icon="el-icon-delete"
						style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
					</li>
				</ul>
			</el-descriptions-item>
		</el-descriptions>

		<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" v-loading='loading'>
			<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')">
			</el-table-column>


			<el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column>
			
			<el-table-column prop="sysOrderNo" :label="$t('i18nn_52a8c38184d84581')"></el-table-column>
			<el-table-column prop="relationNo" :label="$t('i18nn_158db9252e21d1a6')"></el-table-column>
			<el-table-column prop="bizTypeName" :label="$t('i18nn_32b164c53fa35e6d')"></el-table-column>
			
			<el-table-column prop="serviceTypeName" :label="$t('i18nn_61fc9e07fff97930')"></el-table-column>
			
			<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')+'(和'+$t('i18nn_61fc9e07fff97930')+'相关)'"></el-table-column>
			<el-table-column prop="goodsSku" :label="'SKU'+'(非必填)'"></el-table-column>
			<!-- <el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')"></el-table-column> -->
			
			<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
				<template slot-scope="scope">
					<div>
						<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
						<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
					</div>
				</template>
			</el-table-column>

		</el-table>
		
		<el-card>
			<template slot="header">
				<h3>{{$t('i18nn_560595129574513f')}}</h3>
			</template>
		
			<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">undefined</h3>
		</div> -->
			<div>
				<el-table ref="multipleTable" :data="LogtableData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" fixed="left" width="50" align="center" :label="''">
					</el-table-column>
		
					<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
						<template slot-scope="scope">
							<el-tag type="" v-if="'0' == scope.row.status">{{ scope.row.statusName }}</el-tag>
							<el-tag type="success" v-else-if="'10' == scope.row.status">
								{{ scope.row.statusName }}
							</el-tag>
							<el-tag type="warning" v-else-if="'20' == scope.row.status">
								{{ scope.row.statusName }}
							</el-tag>
							<!-- <el-tag type="danger" v-else-if="'3'==scope.row.status">{{scope.row.statusName}}</el-tag> -->
							<el-tag type="info" v-else>{{ scope.row.statusName }}</el-tag>
						</template>
					</el-table-column>
									
					<el-table-column prop="title" :label="$t('i18nn_7431e76029678ec7')"></el-table-column>
									
					<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>
					
					<el-table-column prop="attachments" :label="$t('i18nn_f5d43732e3f6cf4d')">
						<template slot-scope="scope">
							<div @click="openViewFile($event, scope.row)">
								<!-- <div v-for="(item,index) in scope.row.attachments" :key="index" v-if="index<1">
					          <el-link type="primary">{{item.fileName}}</el-link>
					        </div> -->
								<div v-if="scope.row.attachments">
									<el-link type="primary">
										...
										<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
										<span>{{ scope.row.attachments.length }}</span>
										<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
									</el-link>
								</div>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
						<template slot-scope="scope">
							<div>
								<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
								<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
							</div>
						</template>
					</el-table-column>
					
				</el-table>
			</div>
		</el-card>
		
		
		<!--  附件查看 -->
		<whFileView2 :openTime="FileViewOpenTime" :fileList="fileDataList"></whFileView2>
		
	</div>
</template>
<script>
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	import whFileView2 from '@/components/StorageCenter/components/whFileView2.vue';
	
	export default {

		props: {
			openTime:{},
			id: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		components: {
			whFileView2
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				loading: false,

				form: {},

				loading_load: false,
				tableData: [],
				
				LogtableData:[],
				
				FileViewOpenTime: '',
				fileDataList: [],

				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 10, 20, 30],
				// 	page_size: 10,
				// 	total: 0
				// },
				// selectOption: {
				//   wh_weight_unit: [],
				//   wh_vol_unit: [],
				//   wh_goods_fee_type:[],
				//   statusList: [
				//     {
				//       value: '',
				//       label: this.$t('i18nn_16853bda54120bf1')
				//     },
				//     {
				//       value: '1',
				//       label: this.$t('i18nn_fdd8426d7b8869c5')
				//     },
				//     {
				//       value: '2',
				//       label: this.$t('i18nn_619d7e563f879811')
				//     },
				//     {
				//       value: '3',
				//       label: this.$t('i18nn_1dfb3a065d3ffe39')
				//     }
				//   ]
				// },
				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc' //desc降序，asc升序
				// 	// "accountPeriod":"",
				// 	// packId: "",
				// 	// "billNo":""
				// 	// status: '',
				// 	// putWhNo: '',
				// 	// goodsSku: '',
				// 	// hashCode: '',
				// 	// goodsName: '',
				// 	// declareNameCh: '',
				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('watchKey');
				// if (newVal) {
				// console.log('watch openDateTime HyUpLoadImg.vue');
				this.initData();
				// }
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.tableData = [];
				this.form = {};
				if (this.id) {
					// this.tableData = [];
					// this.DetData = {};
					this.getDetPageData(this.id);
					this.getAddServiceDetPageData(this.id);
					
					this.getPageData();
				}
				// this.getDicData();
			},
			// 富文本
			onEditorChange(html) {
				// this.form.contentStr = html;
			},
			
			//打开附件预览
			openViewFile(event, row) {
				event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				this.FileViewOpenTime = new Date().getTime();
				// this.FileUploadOpenTime
				this.fileDataList = row.attachments;
			},
			
			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },

			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			//请求分页数据
			getDetPageData(id) {
				// let _this = this;
				this.loading_load = true;
				this.$http
					// .get(this.$urlConfig.WhWorkOrderDet + '/' + id, {})
					.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.form = data.data;
						
						// this.$nextTick(() => {
						// 	this.$refs.whNoSelect.init(this.form.whNo);
						// })
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//请求分页数据
			getAddServiceDetPageData(id) {
				this.tableData = [];
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhWorkAddServiceDet + '/' + id, {})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//请求分页数据
			getPageData() {
				this.loading_load = true;
			
				this.$http
					.get(this.$urlConfig.WhWorkOrdeLog + "/" + this.id, {
			
					})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.LogtableData = data.rows;
							//取消编辑时调用
							//缓存，待编辑的数据
							// this.tableDataCatch = data.rows;//直接赋值为浅拷贝 concat也是浅拷贝
							// this.tableDataCatch = deepClone(data.rows);
							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('订单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>
