<template>
	<!--  -->
	<div>
		
		<!-- 列表 -->
		<!-- <el-dialog :title="$t('i18nn_560595129574513f')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px" top="10px"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_e46ef79830a8e08c')" append-to-body :visible.sync="dialog" :direction="'rtl'" size="1000px">	
			<div>
				<!-- <div style="margin-bottom:10px; padding:0 10px;">
					<el-button type="primary" @click="openAdd()">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
				</div> -->
				<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" v-loading="loading_load">
					<el-table-column type="index" fixed="left" width="50" align="center" :label="''">
					</el-table-column>
				
					<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
						<template slot-scope="scope">
							<el-tag type="" v-if="'0' == scope.row.status">{{ scope.row.statusName }}</el-tag>
							<el-tag type="success" v-else-if="'10' == scope.row.status">
								{{ scope.row.statusName }}
							</el-tag>
							<el-tag type="warning" v-else-if="'20' == scope.row.status">
								{{ scope.row.statusName }}
							</el-tag>
							<!-- <el-tag type="danger" v-else-if="'3'==scope.row.status">{{scope.row.statusName}}</el-tag> -->
							<el-tag type="info" v-else>{{ scope.row.statusName }}</el-tag>
						</template>
					</el-table-column>
				
					<el-table-column prop="title" :label="$t('i18nn_7431e76029678ec7')"></el-table-column>
				
					<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>
					
					<el-table-column prop="attachments" :label="$t('i18nn_f5d43732e3f6cf4d')">
						<template slot-scope="scope">
							<div @click="openViewFile($event, scope.row)">
								<!-- <div v-for="(item,index) in scope.row.attachments" :key="index" v-if="index<1">
					          <el-link type="primary">{{item.fileName}}</el-link>
					        </div> -->
								<div v-if="scope.row.attachments">
									<el-link type="primary">
										...
										<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
										<span>{{ scope.row.attachments.length }}</span>
										<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
									</el-link>
								</div>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
						<template slot-scope="scope">
							<div>
								<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
								<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
							</div>
						</template>
					</el-table-column>
					
					
				</el-table>
			</div>
			
		
			<!-- <div slot="footer" class="dialog-footer"> -->
				<!-- <el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
				<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
			<!-- </div> -->
		</el-drawer>
		<!-- </el-dialog> -->
		
		<!-- 新增 -->
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" append-to-body :close-on-click-modal="false" :visible.sync="dialogAddVisible"
			width="1000px">
			<el-card style="" v-loading="loading">
				<div slot="header">
					<h3>{{$t('i18nn_8bafd5202547e50d')}}</h3>
				</div>
				<el-form :model="form" label-width="100px">
					
					<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="" required>
						<el-input type="textarea" v-model="form.remark"></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="">
						<div>
							<el-button type="primary" icon="el-icon-paperclip" @click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>
							<ul>
								<li v-for="(item,index) in form.attachments" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
										:fit="'contain'" :src="item.url" :preview-src-list="[item.url]">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
									<el-button @click="delFile(index)" type="text" size="mini"
										icon="el-icon-delete" style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
								</li>
							</ul>
						</div>
					</el-form-item>
				</el-form>
			</el-card>
		
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
				<el-button type="primary" plain @click="dialogAddVisible = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button>
			</div>
		</el-dialog> -->
		
		<!-- 附件 -->
		<!-- <dialogFileUpload ref="dialogFileUpload" :folder="'storage/addValService/'" :fileKey="'addValServiceFile'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload> -->
		
		<!--  附件查看 -->
		<whFileView2 :openTime="FileViewOpenTime" :fileList="fileDataList"></whFileView2>
			
	</div>
	
</template>
<script>
	import whFileView2 from '@/components/StorageCenter/components/whFileView2.vue';
	// import barcode from '@/components/Common/barcode.vue';
	// import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	
	export default {
		props: {
			openTime: {},
			row: {
				default: function() {
					return {};
				},
				type: Object
			}
		},
		components: {
			whFileView2,
			// dialogFileUpload
		},
		data() {
			return {
				dialog: false,
				loading: false,
				loading_load: false,
				tableData: [],
				
				// dialogAddVisible: false,
				// FileUploadOpenTime: '',
				
				FileViewOpenTime: '',
				fileDataList: [],
				
				// form: {
				// 	id: null,
				// 	"remark": "",
				// 	attachments: [],
				// },
				
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.tableData = [];
				if (this.row && this.row.id) {
					this.getListData();
				} else {
					this.$message.warning("ID为空");
				}
			},
			
			//打开附件预览
			openViewFile(event, row) {
				event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				this.FileViewOpenTime = new Date().getTime();
				// this.FileUploadOpenTime
				this.fileDataList = row.attachments;
			},
			
			//请求分页数据
			getListData() {
				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.WhWorkOrdeLog + "/" + this.row.id, {})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},
			
			//打开新增
			// openAdd(){
			// 	this.form = {
			// 		id: this.row.id,
			// 		"remark": "",
			// 		attachments: [],
			// 	};
			// 	this.dialogAddVisible = true;
			// },
			
			
			//提交信息
			// submitForm() {
			// 	// let bizType = this.fomatterQaTypeToBizType(this.qaType);
			// 	let formData = Object.assign({}, this.form);
			// 	// formData.relationId = this.relationId;
			// 	// formData.bizType = bizType;
			// 	this.postData(this.$urlConfig.WhValAddServiceLogAdd, formData, '', () => {
			// 		this.dialogAddVisible = false;
			// 		// this.$emit('success')
			// 		this.getListData();
			// 		// this.$message.success(this.$t('9f30371831a98237'));
			// 	});
			// },
			
			//添加附件
			// openAddFile() {
			// 	// this.FileUploadOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			// //删除附件
			// delFile(index) {
			// 	this.form.attachments.splice(index, 1);
			// 	this.$forceUpdate();
			// },
			
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);
			// 	let fileList = data.map(item => {
			// 		return {
			// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
			// 			// type: "90"
			// 		}
			// 	});
			// 	if (!this.form.attachments) {
			// 		this.form.attachments = [];
			// 	}
			// 	this.form.attachments = this.form.attachments.concat(fileList);
			// 	this.$forceUpdate();
			// },
			
			//提交数据
			// postData(url, formData, type, callback) {
			// 	// let _this = this;
			// 	this.loading = true;
			
			// 	let HttpType = {};
			// 	if ('delete' == type) {
			// 		HttpType = this.$http.delete(url, formData);
			// 	} else {
			// 		HttpType = this.$http.put(url, formData);
			// 	}
			// 	HttpType
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
