<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">{{$t('i18nn_2f7a4906f66bffd1')}}</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_f80dedf7c601059f')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="openAddVal()" size="small" icon="el-icon-plus">{{$t('i18nn_2a714652d0b17e0d')}}</el-button>
							
						<!-- </li> -->
					<!-- </ul> -->
					
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		
		<div class="filterCon" style="">
			<div class="filterBarList">
					<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					  <el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
							<span>{{i18nFormatter(item.value)}}</span>
						</el-menu-item>
					</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="openAddVal()" size="small" icon="el-icon-plus">{{$t('i18nn_2a714652d0b17e0d')}}</el-button>
						
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
					</li>
					<!-- <li>
						<span>{{$t('i18nn_f566f50ef680920c')}}</span>
						<el-select filterable clearable v-model="filterData.orderType" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="initData()">
							<el-option v-for="item in selectOption.wh_work_order_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</li> -->
					<li>
						<span>{{$t('i18nn_6235565b185f0725')}}</span>
							<el-input 
							 type="text"
							
							v-model="filterData.workNo" 
							size="small" 
							clearable 
							@keyup.enter.native="initData()" 
							maxlength="50" 
							:placeholder="$t('FormMsg.Please_Enter')"
							style="width: 180px;"
							/>
						
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input
							type="text"
							v-model="filterData.keyword"
							size="small"
							clearable
							@keyup.enter.native="initData()"
							maxlength="50"
							:placeholder="$t('i18nn_3ee4c9b76289e93a')"
							style="width: 180px;"
						/>
					</li>
					<!-- <li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
					</li> -->
					
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
		</div>
		
		<!-- <div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="initData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_f566f50ef680920c')}}</span>
				<el-select filterable clearable v-model="filterData.orderType" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="initData()">
					<el-option v-for="item in selectOption.wh_work_order_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
				</el-select>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_6235565b185f0725')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo" maxlength="50" :clearable="true" style="width: 220px;"></el-input>
			</div>
			<el-button type="primary" @click="initData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
			<el-button type="success" @click="$router.push({'name':'createWorkOrder'})" size="small" icon="el-icon-plus">{{$t('i18nn_a38569d545d8e238')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" :max-height="$store.state.frameConHeightWh1">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<!-- "id":"",
            "cusNo":"客户编码",
            "packageNo":"包装编号",
            "goodsSku":"产品代码",
            "goodsImg":"产品图片",
            "goodsName":"产品名称",
            "weightUnit":"重量单位",
            "goodsWeight":"单件重",
            "volumeUnit":"体积单位",
            "goodsLength":"长",
            "goodsWidth":"宽",
            "goodsHeight":"高",
            "goodsCount":"入库件数"

            "whFeeType":"仓租收费类型",

                "whFeeAmt":"仓租单件",-->

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning" v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" v-else-if="'90'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')">
					<template slot-scope="scope">
						<div>
							<!-- <el-link v-if="scope.row.workNo" type="danger" size="mini"
								@click="openView($event, scope.row, scope.$index)">
								{{ scope.row.workNo }}
							</el-link> -->
							<div class="link-copy">
								<span class="link-text" @click="openView($event, scope.row, scope.$index)">
									{{ scope.row.workNo }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
								  <i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.workNo)"></i>
								</el-tooltip>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
				<el-table-column prop="whNo" :label="$t('Storage.skuInfo.warehouse')"></el-table-column>
				<el-table-column prop="title" :label="$t('i18nn_7431e76029678ec7')" min-width="200">
					<template slot-scope="scope">
						<!-- <div @click="showDetList($event,scope.row)"> -->
						<!-- <span style="color:#206ce0; font-weight: bold; text-decoration: underline; cursor: pointer;"> -->
						{{ scope.row.title }}
						<!-- </span> -->
						<!-- </div> -->
					</template>
				</el-table-column>

				<el-table-column prop="orderTypeName" :label="$t('i18nn_f566f50ef680920c')">
					<template slot-scope="scope">
						<div>
							{{ $Utils.i18nKeyText(scope.row,'orderTypeName') }}
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="addServiceList" :label="$t('i18nn_18f412662f2b18ba')" min-width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.addServiceList" class="recDet">
							<div class="recItem" v-for="(item,index) in scope.row.addServiceList" :key="index" v-if="index<5">
								<span class="recItem-sku">{{item.serviceTypeName}}</span>&nbsp;*&nbsp;
								<strong class="recItem-num">{{item.quantity}}</strong>
							</div>
							<div v-if="scope.row.addServiceList.length>5">
								<el-link type="primary" @click="openView($event, scope.row, scope.$index)">
									..
									<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
									<span>&nbsp;{{scope.row.addServiceList.length}}&nbsp;</span>
									<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis red" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				<el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')" class="">
				    <template slot-scope="scope">
				      <div @click="openUploadFile($event, scope.row)">
				        <!-- <div v-for="(item,index) in scope.row.attachments" :key="index" v-if="index<1">
				          <el-link type="primary">{{item.fileName}}</el-link>
				        </div> -->
				        <div v-if="scope.row.attachments">
				          <el-link type="primary">...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span></el-link>
				        </div>
				      </div>
				    </template>
				</el-table-column>
				
				<!-- <el-table-column prop="createTime" :label="$t('i18nn_14b5f4f572736efe')"></el-table-column> -->
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_fdc34fd7121f9c48')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_726c51702f70c486')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="260px" fixed="right" align="left">
					<template slot-scope="scope">
					<div>
						<div style="margin-bottom: 10px;">
							<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus" v-if="'0' == scope.row.status">{{ $t('FormMsg.Delete') }}</el-button>
							
							<el-button @click="openEdit($event, scope.row, scope.$index)"
								type="warning" size="mini" icon="el-icon-edit" v-if="'0' == scope.row.status||'10' == scope.row.status||'90'==scope.row.status">
							{{ $t('FormMsg.Edit') }}
							</el-button>
						</div>
						
						<!-- <div  style="margin-bottom: 10px;" v-if="'0' == scope.row.status">
						  <el-button @click="commitAction($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-sell">{{$t('i18nn_9b73b93c1607cd97')}}</el-button>
						</div> -->
						
						<!-- <div style="margin-bottom: 10px;" v-if="'40'==scope.row.orderType">
							<el-button @click="openUploadLabelFile($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-upload2">{{$t('i18nn_bd6d1ded173dc477')}}</el-button>
						</div> -->
						
						
						<!-- <div style="margin-bottom: 10px;">
							<el-button v-if="'40'== scope.row.orderType" @click="toDropShipping($event, scope.row)" type="primary" size="mini" icon="el-icon-right">{{$t('i18nn_299f1f6fef7c640e')}}</el-button>
							<el-button @click="showDetList($event, scope.row)" type="primary" size="mini" icon="el-icon-view">{{ $t('FormMsg.details_View') }}</el-button>
						</div> -->
						<div style="margin-bottom: 10px;">
							<!-- <el-button @click="openView($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-view">{{$t('i18nn_377fa66b0b28ce31')}}</el-button> -->
							<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini" icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
						<!-- </div> -->
						
						<!-- <div style="margin-bottom: 10px;"> -->
							<el-button type="primary" size="mini" icon="el-icon-view"
								@click="openDioalogLog($event, scope.row)">{{$t('i18nn_e46ef79830a8e08c')}}</el-button>
						</div>
						<!-- <div v-if="'0' == scope.row.status"> -->
							<!-- <el-button @click="shipmentsAction($event, scope.row, scope.$index)" type="success" size="mini" icon="el-icon-sell">{{$t('Storage.packing_list.shipments')}}</el-button> -->
						<!-- </div> -->
					</div>
						
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="0" :title="dialogFormMsg" :visible.sync="dialogFormVisible" custom-class="myFullDialog4">
			<el-form ref="form" size="small" :rules="formRules" :model="form" label-width="150px" style="width: 1100px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

				<h3 class="titSty1">{{ $t('Storage.packing_list.basic_information') }}</h3>
				
				<el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="">
					<el-input type="text" v-model="form.title" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_f566f50ef680920c')" prop="">
					<el-select filterable clearable size="mini" v-model="form.orderType" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
						<el-option v-for="item in selectOption.wh_work_order_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_3e358d140504ab46')" prop="detail" class="">
				  <HyQuillEditor ref="myTextEditor" :value="form.contentStr" @editorChange="onEditorChange" class=""></HyQuillEditor>
				</el-form-item>
				
				<h3 class="titSty1">{{$t('i18nn_8200a25ab641ba97')}}</h3>
				<div>
					<el-card>
						<HyElWhUpLoad
							ref="HyElWhUpLoad"
							:auto_upload="true"
							:listType="''"
							:watchKey="fileWatchKey"
							:fileListShow="fileList"
							:fileId="fileId"
							:folder="'storage/attachment/'"
							:fileKey="'workOrder'"
							:bucket="'hytx-oss-wh'"
							:accept="'all'"
							:maxSizeMB="50"
							:limit="10"
							v-on:UploadSuccessUrl="changeFileUrl"
						></HyElWhUpLoad>
					</el-card>
				</div>
			

				<h3 class="titSty1">{{ $t('Storage.packing_list.Other') }}</h3>
				<el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog> -->


		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('FormMsg.details_View')" append-to-body :visible.sync="dialogShowDet" :direction="'rtl'" size="1200px">
			<div style="padding:10px;"><workOrderDet :openTime="workDetOpenTime" :id="workId"></workOrderDet></div>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{ $t('FormMsg.Close') }}</el-button>
			</div> -->
		</el-drawer>
		<!-- </el-dialog> -->
		
		<!-- 处理日志 -->
		<dialogLogList :openTime="dialogLogOpenTime" :row="OpenLogData" @success="LogSubmitSuccess"></dialogLogList>
		
		<!--  附件上传 -->
		<whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess"></whFileUpload>
		<!--   -->
		<!-- <whLabelFileUpload :openTime="FileUploadOpenTime" :fileKey="FileUploadKey" :workNo="fileWorkNo" @success="FileUploadSuccess"></whLabelFileUpload> -->
		<workOrderValueAddServiceDialog :openTime="addValOpenTime" :id="addValId" @success="successBack"></workOrderValueAddServiceDialog>
		
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="{}"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhWorkAddServicePageList"
			:expHttpFilter="pageFilterData()" :noShowExpDefExcel="true" :hasFormatter="true" :formatterData="formatterData">
		</whExcelCustom>
		
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	import {
		workOrderList_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';
	
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	
	import dialogLogList from '@/components/StorageCenter/workOrder/dialogLogList.vue';

import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	
import workOrderValueAddServiceDialog from '@/components/StorageCenter/workOrder/workOrderValueAddServiceDialog.vue';

import workOrderDet from '@/components/StorageCenter/workOrder/workOrderDet.vue';
// import workOrderFileUpload from '../components/workOrderFileUpload.vue';
// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	// props: {
	//   // mobile:"",
	//   isSel: {
	//     default: function() {
	//       return false;
	//     },
	//     type: Boolean
	//   },
	//   status: {
	//     default: function() {
	//       return '';
	//     },
	//     type: String
	//   }
	// },
	components: {
		whExcelCustom,
		whFileUpload,
		workOrderDet,
		dialogLogList,
		// whLabelFileUpload,
		SelAgentUser,
		whNoSelect,
		workOrderValueAddServiceDialog
		// workOrderFileUpload
	},
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},
			
			dialogShowDet: false,
			workId: null,
			workDetOpenTime:'',
			addValOpenTime:"",
			addValId:"",
			
			fileRelationId:'',
			FileUploadOpenTime:'',
			
			//处理日志
			dialogLogOpenTime:'',
			OpenLogData:{},
			
			// fileWorkNo: '',
			// FileUploadOpenTime: '',
			// FileUploadKey:'',

			// isShowFileUpload: false,
			// fileRelationId: '',
			// FileUploadOpenTime: '',
			// activeName: 'second',
			// isShowFrom:false,
			// pickerOptions1: {
			//     disabledDate(time) {
			//       return time.getTime() > Date.now();
			//     },
			//     shortcuts: [{
			//       text: this.$t('i18nn_0200bd47bb4bb83d'),
			//       onClick(picker) {
			//         picker.$emit('pick', new Date());
			//       }
			//     }, {
			//       text: this.$t('i18nn_84b73bfc6dada445'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24);
			//         picker.$emit('pick', date);
			//       }
			//     }, {
			//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
			//         picker.$emit('pick', date);
			//       }
			//     }]
			//   },
			//打开入库选择框
			dialogSelVisible: false,
			openRowData: {},
			openRowIndex: {},

			UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改
			dialogFormMsg: '',

			//导入excel
			dialogUploadVisible: false,
			loadingExcel: false,
			fileExcel: '',
			excelData: [],
			excelFileName: '',

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading: false,

			loading_det: false,

			// form: {
			//   id: '', //"数据ID",
			//   packingNo: '', //this.$t('i18nn_b7a10fe4e9528362'),
			//   leavePort: '', //this.$t('i18nn_ed47d0c198c61d01'),
			//   arrivePort: '', //this.$t('i18nn_d3d95bf498ae6cdb'),
			//   leaveDate: '', //this.$t('i18nn_04e92fdefcddec84'),
			//   arriveDate: '', //this.$t('i18nn_b5c03c6f1b9b1e28'),
			//   packageType: '', //this.$t('i18nn_b799f516e3940e3f'),
			//   packRecordList: [
			//     {
			//       goodsSku: '', //this.$t('i18nn_31f887c1ada79a99'),
			//       // cusCode: '', //this.$t('i18nn_64fed2851f79d875'),
			//       goodsName: '', //this.$t('i18nn_b23004db5349dfd2'),
			//       goodsNameEn: '', //this.$t('i18nn_05141ac0139eaf01'),
			//       goodsImg: '', //this.$t('i18nn_877c5a0e44a0eb07'),
			//       // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//       // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//       // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//       // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//       declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),
			//       goodsWeight: '', //this.$t('i18nn_11e41b2ec3d872ed'),
			//       // weightUnit: '', //this.$t('i18nn_8e01866868930d09'),
			//       sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			//       goodsLength: '', //this.$t('i18nn_2cba96917484569a'),
			//       goodsWidth: '', //this.$t('i18nn_9e24e0e4745f4948'),
			//       goodsHeight: '', //this.$t('i18nn_c5fa0857bc0df2d6'),
			//       safeStock: '', //this.$t('i18nn_004617dc47191cb8'),
			//       packCount: '', //this.$t('i18nn_b9d4d3f6183bf3c1'),
			//       proxyCus: '' //货代客人
			//       // "id":"",//"数据id"
			//     }
			//   ]
			// },
			//附件
			fileWatchKey: '1',
			fileList: [],
			// fileId: this.$store.getters.getUserInfo.id,

			// form: {
			// 	id: '', //"数据ID",
			// 	title: '', //this.$t('i18nn_7431e76029678ec7'),
			// 	orderType: '', //this.$t('i18nn_f566f50ef680920c'),
			// 	contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
			// 	attachments: [
			// 		{
			// 			id: '', //"数据ID",
			// 			url: '', //"文件URL",
			// 			fileName: '' //this.$t('i18nn_89221ad15d2ec113')
			// 		}
			// 	]
			// },

			// formRules: {
			// 	title: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	contentStr: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	orderType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]

			// 	// defCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// hashCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// declareNameCh: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// declareNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// weightUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
			// 	// goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// volumeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
			// 	// whFeeType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// 	// whFeeAmt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
			// },
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				// wh_no: [],
				// wh_vol_unit: [],
				// wh_goods_fee_type: [],
				wh_work_order_type: [],
				// wh_packing_type: [],
				statusList: workOrderList_status
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序
				agentUser:"",
				// "accountPeriod":"",
				// "billNo":""
				status: '',
				workNo: '',
				orderType:'',
				whNo:'',
				keyword:'',
				
				// putWhNo: '',
				// goodsSku: '',
				// hashCode: '',
				// goodsName: '',
				// declareNameCh: ''
			}
		};
	},
	
	//创建时
	created() {
		// this.getPageData();
		if (this.$route.query.state) {
			this.filterData.status = this.$route.query.state;
			// this.initData();
		}
		if (this.$route.query.workNo) {
			this.filterData.workNo = this.$route.query.workNo;
			// this.initData();
		}
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		//数据字典
		getDicData(['wh_work_order_type'],
			(data)=>{
				this.selectOption.wh_work_order_type = data['wh_work_order_type'];
		});
		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},
		i18nFormatter(value) {
			return i18nStatus(value, this.selectOption.statusList)
		},
		changeAgentUser(val){
			this.filterData.agentUser = val;
			this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		
		//导出 excel
		exportExcel() {
			let columns = [
				{
					title: this.$t('Storage.packing_list.status'),
					key: 'statusName'
				},
				{
					title: this.$t('i18nn_6235565b185f0725'),
					key: 'workNo'
				},
				
				{
					title: this.$t('Storage.skuInfo.warehouse'),
					key: 'whNo'
				},
		
				{
					title: this.$t('i18nn_7431e76029678ec7'),
					key: 'title'
				},
				{
					title: this.$t('i18nn_f566f50ef680920c'),
					key: 'orderTypeName'
				},
		
		
				{
					title: this.$t('Storage.tableColumn.remark'),
					key: 'remark'
				},
				{
					title: this.$t('i18nn_fdc34fd7121f9c48'),
					key: 'updateTime'
				},
				{
					title: this.$t('i18nn_726c51702f70c486'),
					key: 'createTime'
				},
				//-----明细--二层
				{
					title: this.$t('i18nn_52a8c38184d84581'),
					key: 'sysOrderNo'
				},
				{
					title: this.$t('i18nn_158db9252e21d1a6'),
					key: 'relationNo'
				},
				{
					title: this.$t('i18nn_32b164c53fa35e6d'),
					key: 'bizTypeName'
				},
				{
					title: this.$t('i18nn_61fc9e07fff97930'),
					key: 'serviceTypeName'
				},
				{
					title: this.$t('i18nn_f4c3e91a86d23afe')+this.$t('i18nn_f9db93b87e08763b'),
					key: 'quantity'
				},
				{
					title: 'SKU',
					key: 'goodsSku'
				},
			];
			let Data = [];
		
		
			// if (!this.filterData.userId) {
			// 	this.$message.warning(this.$t('i18nn_5184f7a4033b4969'));
			// 	return;
			// }
		
			this.expExcelData = Data;
			this.excelHead = columns;
		
			this.excelName = 'addServiceList';
		
			this.excelOption = {
				height: 20,
				hasShowMerge: true,
				mergeLengthKey: 'addServiceList',
				mergeDataCol: ['A', 'B', 'C', 'D', 'E', 'F', 'G','H'],
				// mergeDataRow:[1,2],
			};
			this.excelOpenTime = new Date().getTime();
		
			// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
		},
		
		openAddVal(){
			this.addValId = "";
			this.addValOpenTime = new Date().getTime();
		},
		
		//打开编辑
		openEdit(event, row, index) {
			event.stopPropagation();
			this.addValId = row.id;
			this.addValOpenTime = new Date().getTime();
		},
		
		successBack(){
			this.initData();
		},
		
		//处理日志
		openDioalogLog(event, row){
			event.stopPropagation();
			this.dialogLogOpenTime = new Date().getTime();
			this.OpenLogData = row;
		},
		LogSubmitSuccess(data) {
			this.initData();
		},
		
		
		//打开查看
		openView(event, row, index) {
			event.stopPropagation();
			// if('40'==row.orderType){//一件代发
			// 	this.$router.push({'name':'WarehouseDropShipping',query:{workNo:row.workNo}})
			// } else if('30'==row.orderType){//自提单
			// 	this.$router.push({'name':'WarehouseShipments',query:{workNo:row.workNo}})
			// } else if('60'==row.orderType){//入库申请
			// 	this.$router.push({'name':'InWarehouse',query:{workNo:row.workNo}})
			// } else if('50'==row.orderType){//退货换标
			// 	this.$router.push({'name':'WarehouseExchangeReturn',query:{workNo:row.workNo}})
			// } else {//其他工单
			// 	this.$router.push({'name':'createWorkOrder',query:{id:row.id,orderType:row.orderType}})
			// }
			// this.$router.push({'name':'workOrderDet',query:{id:row.id}})
			// this.openDioalog(row, this.$t('FormMsg.Edit'));
			// this.getPageDetData(row.id);
			
			this.dialogShowDet = true;
			this.workId = row.id;
			this.workDetOpenTime = new Date().getTime();
		},
		
		//打开附件上传
		openUploadFile(event, row){
		  event.stopPropagation();
		  // this.isShowFileUpload = true;
		  this.fileRelationId = row.id;
		  this.FileUploadOpenTime = new Date().getTime();
		},
		//附件上传成功回调
		FileUploadSuccess(data){
		  this.initData();
		  // event.stopPropagation();
		  // this.isShowFileUpload = true;
		  // this.fileRelationId = row.id;
		  // this.FileUploadOpenTime = new Date().getTime();
		},
		
		
		
		//删除
		delAction(event, row) {
			event.stopPropagation();
			this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
				
				type: 'warning'
			})
				.then(() => {
					this.delDataAction(event, row);
				})
				.catch(() => {
				
				});
		},
		//删除
		delDataAction(event, row) {
			event.stopPropagation();
			console.log('delDataAction', row);
			
			this.postData(this.$urlConfig.WhWorkOrderDel + '/' + row.id, {}, 'delete');
		},
		
		//打开label上传
		// openUploadLabelFile(event, row) {
		//   event.stopPropagation();
		//   this.fileWorkNo = row.workNo;
		// 	// this.FileUploadKey = row.orderNumber;
		//   this.FileUploadOpenTime = new Date().getTime();
		// },
		
		//label上传成功回调
		// FileUploadSuccess(data) {
		//   // this.initData();
		// 	console.log(data);
		// },
		
		//提交工单
		// commitAction(event, row) {
		//   event.stopPropagation();
		
		//   // this.$confirm(this.$t('Storage.DropShipping.sure_submit_1')+parm.length+this.$t('Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
		//   //   // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//   //   // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//   //   type: 'warning'
		//   // })
		//   //   .then(() => {
		//       this.postData(this.$urlConfig.WhWorkOrderSubmit+'/'+row.id, {});
		//     // })
		//     // .catch(() => {});
		// },

		

		//提交信息
		postData(url, formData, type) {
			// HttpTypelet _this = this;
			this.loading = true;
			this.loading_load = true;
			let HttpType = {};
			if ('delete' == type) {
				HttpType = this.$http.delete(url, formData);
			} else {
				HttpType = this.$http.put(url, formData);
			}
			HttpType.then(({ data }) => {
				console.log(this.$t('i18nn_bc868e024b80d2e3'));
				console.log(data);
				
				this.loading = false;
				this.loading_load = false;
				if (200 == data.code) {
					// this.dialogFormVisible = false;
					this.getPageData();
					
					if("delete"==type){
						this.$message({
						    type: 'success',
						    message: this.$t('tips.successfullyDelete')
						});
					} else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
					}
					
				} else {
					// if (!data.msg) {
					//   data.msg = this.$t('tips.submitError');
					// }
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				});
			});
		},


		//查询数据
		// serPageData() {
		// 	this.pagination.current_page = 1;
		// 	this.getPageData();
		// },
		handleSelectStatus(key, keyPath) {
		  // console.log(key, keyPath);
			this.filterData.status = key;
			this.initData();
		},
		//格式换待导出去数据
		formatterData(arr) {
			let newArr = [];
			arr.forEach(item => {
				if (item.addServiceList && item.addServiceList.length > 0) {
					// {
					// 	title: this.$t('i18nn_52a8c38184d84581'),
					// 	key: 'sysOrderNo'
					// },
					// {
					// 	title: this.$t('i18nn_32b164c53fa35e6d'),
					// 	key: 'bizTypeName'
					// },
					// {
					// 	title: this.$t('i18nn_61fc9e07fff97930'),
					// 	key: 'serviceTypeName'
					// },
					// {
					// 	title: this.$t('i18nn_f9db93b87e08763b')+'(和'+this.$t('i18nn_61fc9e07fff97930')+'相关)',
					// 	key: 'quantity'
					// },
					// {
					// 	title: 'SKU'+'(非必填)',
					// 	key: 'goodsSku'
					// },
					item.addServiceList.forEach(item2 => {
						
						newArr.push(Object.assign({}, item, {
		
							sysOrderNo: item2.sysOrderNo,
							relationNo: item2.relationNo,
							bizTypeName: item2.bizTypeName,
							serviceTypeName: item2.serviceTypeName,
							quantity: item2.quantity,
							goodsSku: item2.goodsSku,
						}));
					})
				} else {
					newArr.push(item);
				}
			});
			console.log('newArr', newArr);
			return newArr;
		},
		
		//分页的筛选项数据
		pageFilterData() {
		
			return {
				workNo: this.filterData.workNo ? this.filterData.workNo : null,
				proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				status: this.filterData.status ? this.filterData.status : null,
				orderType: this.filterData.orderType ? this.filterData.orderType : null,
				whNo: this.filterData.whNo ? this.filterData.whNo : null,
				keyword: this.filterData.keyword ? this.filterData.keyword : null,
			};
		},
		
		//请求分页数据
		getPageData() {
			
			let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size //当前页显示数目
				},
				this.pageFilterData()
			);
			
			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhWorkAddServicePageList, filterData)
				.then(({ data }) => {
					console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		
		// toDropShipping($event, row) {
		// 	$event.stopPropagation();
		// 	this.$router.push({name:'WarehouseDropShipping',query:{workOrderId:row.id}})
			
		// },
		//
		// showDetList($event, row) {
		// 	$event.stopPropagation();
		// 	this.dialogShowDet = true;
		// 	this.workId = row.id;
		// },
		
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
		// 				// this.selectOption.wh_no = data.data['wh_no'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
</style>
